body {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #f1f1f1;
  color: #23282d;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  outline: none;
  -webkit-user-drag: none;
}

img,
img *,
svg,
svg * {
  user-select: none;
}
