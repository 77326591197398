.container {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
}
