.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
}

.sidebar {
  width: var(--sidebar-width);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - var(--sidebar-width));
  height: 100vh;
}

@media (max-width: 992px) {
  .container {
    overflow-x: hidden;
  }
  .content {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
  }
  .sidebar {
    width: 0px;
    min-width: 0px;
    overflow: hidden;
    transition: all 100ms ease-out;
  }
  .open {
    min-width: var(--sidebar-width);
    width: var(--sidebar-width);
  }

  .sidebar > div {
    transform: translateX(-100%);
    transition: all 100ms ease-out;
  }
  .open > div {
    transform: translateX(0%);
  }
}
