.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 26px 48px 2px;
}

.spacer {
  flex: 1 1 auto;
}

.title {
  color: var(--palette-text-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
}

.container button {
  margin-left: 24px;
  width: 36px;
  padding: 0;
}

/* Menu */

.menu {
  display: grid;
  padding: 4px;
}

.item {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: var(--palette-text-primary);
  padding: 6px 20px;
  border-radius: 8px;
  font-weight: 500;
}

.item:hover {
  background: var(--light-white-background);
}

.item:focus,
.item:active {
  color: var(--palette-text-secondary);
}

/* Hamburger button */

.hamburger {
  display: none !important;
  flex-direction: column;
  margin-left: 0 !important;
  margin-right: 20px;
  padding: 0 !important;
  gap: 0px;
  min-width: 36px;
}

@media (max-width: 992px) {
  .hamburger {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 26px 24px 28px;
  }
  .title {
    font-size: 17px;
  }
  .container button {
    margin-left: 18px;
  }
}

@media (max-width: 520px) {
  .title {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 12px;
  }
  .container button {
    margin-left: 14px;
  }
}
