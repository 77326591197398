.container {
  background: var(--palette-default);
  border-radius: 10px;
  padding: 36px 24px;
}

.title {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
