.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  min-height: 100vh;
  background: var(--palette-default);
  border-radius: 0px 20px 0px 0px;
  padding: 24px;
}

/* Logo */

.logo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--palette-text-default);
  margin-bottom: 42px;
  cursor: pointer;
}

.logo:hover {
  opacity: 0.8;
}

/* Menu */

.menu {
  display: grid;
  gap: 22px;
}

/* Link */

.link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--palette-text-secondary) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  box-sizing: border-box;
  text-decoration: none;
  white-space: nowrap;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}

.link img {
  margin: 0 6px 0 0;
  filter: grayscale(100%) brightness(150%);
}

/* Link active */

.activeLink {
  position: relative;
  color: var(--palette-text-default) !important;
}

.activeLink img {
  filter: none;
  opacity: 1;
}
