/* Date */

.date {
  max-width: 200px;
  width: 100%;
  white-space: nowrap;
}

.date img {
  margin: 0 6px 0 0;
}

.date img:not(:first-child) {
  margin-left: 12px;
}
